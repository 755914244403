/* eslint-disable */
import React, { useState, useEffect } from "react";
import { fetchUserAttributes } from "@aws-amplify/auth"; // For user attributes
import { list, getUrl } from "@aws-amplify/storage"; // For listing files and generating signed URLs for download
import {
  Button,
  Loader,
  Text,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@aws-amplify/ui-react";
import "./ViewFiles.css";

const ViewFiles = ({ goToHome }) => {
  // State variables
  //const folder = "essentia/"; // Hardcoded folder for testing
  const [files, setFiles] = useState([]); // Holds the list of files/folders
  const [loading, setLoading] = useState(false); // Indicates loading state
  const [error, setError] = useState(null); // Store error messages
  const [userEmail, setUserEmail] = useState(""); // User email for display
  const [folderAccess, setFolderAccess] = useState(""); // User folder access attribute
  const [selectedFiles, setSelectedFiles] = useState([]); // Track selected files for download

  useEffect(() => {
    // Initialize the component by fetching user attributes and listing files
    const initialize = async () => {
      try {
        setLoading(true);

        // Fetch Cognito user attributes
        const userAttributes = await fetchUserAttributes();
        if (process.env.NODE_ENV === "development") {
          console.log("User Attributes:", userAttributes);
        }

        // Set user email and folder access attribute
        setUserEmail(userAttributes.email || "Unknown");
        setFolderAccess(userAttributes["custom:folderAccess"] || "None");

        // Fetch files in the folder specified by the custom attribute folderAccess
        fetchFiles(userAttributes["custom:folderAccess"]);
      } catch (err) {
        console.error("Error initializing view:", err);
        setError("Failed to initialize. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    initialize();
  }, []);

  // Fetch files for the given folder
  const fetchFiles = async (folderPath) => {
    try {
      setLoading(true);
      setError(null);

      if (process.env.NODE_ENV === "development") {
        console.log("Fetching files from folder:", folderPath);
      }

      // List files and folders within the specified folderPath
      const response = await list({
        path: folderPath,
        options: {
          listAll: true,
        },
      });

      // Filter out the folder path itself (the folder we're viewing), but keep subfolders and files
      if (response.items && response.items.length > 0) {
        const filteredFiles = response.items.filter((item) => {
          // Only exclude the folder path
          return item.path !== `${folderPath}/`;
        });
        setFiles(filteredFiles); // Use the filtered list
      } else {
        setFiles([]);
      }

      if (process.env.NODE_ENV === "development") {
        console.log("Files fetched:", response.items);
      }
    } catch (err) {
      console.error("Error fetching files:", err);
      setError("Failed to load files. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Handles selecting or deselecting a file for download
  const handleFileSelect = (filePath) => {
    setSelectedFiles(
      (prevSelected) =>
        prevSelected.includes(filePath)
          ? prevSelected.filter((path) => path !== filePath) // Deselect
          : [...prevSelected, filePath] // Add to selection
    );
  };

  // Handles downloading of the selected files
  const handleDownloadSelected = async () => {
    if (selectedFiles.length === 0) {
      setError("Please select at least one file to download.");
      return;
    }

    try {
      for (const filePath of selectedFiles) {
        // Generate a signed URL for downloading the file
        const { url } = await getUrl({
          path: filePath,
          options: { expiresIn: 900 },
        });

        // Trigger file download
        const a = document.createElement("a");
        a.href = url;
        a.download = filePath.split("/").pop();
        a.style.display = "none";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }

      //alert("Files downloaded successfully.");
    } catch (err) {
      console.error("Error downloading files:", err);
      setError("Failed to download selected files.");
    }
  };

  return (
    <div className="view-files-header">
      {/* Display the Object count in the current folder */}
      <h1>
        Objects <span>({files.length})</span>
      </h1>

      {/* Display Cognito user attributes */}
      <Text>User Email: {userEmail}</Text>
      <Text>Folder Access: {folderAccess}</Text>

      {loading && <Loader />}
      {error && <Text style={{ color: "red" }}>{error}</Text>}

      {/* Go Back button */}
      <Button
        onClick={goToHome}
        className="go-back-button"
        style={{ marginBottom: "20px" }}
      >
        Go Back
      </Button>

      {/* Display error message if No files found */}
      {!loading && !error && files.length === 0 && (
        <Text>No files found in the folder.</Text>
      )}

      {/* Download selected files button */}
      {files.length > 0 && (
        <Button
          onClick={handleDownloadSelected}
          className="clear-files-button"
          style={{ marginBottom: "20px" }}
        >
          Download
        </Button>
      )}

      {/* Display files in a table */}
      {files.length > 0 && (
        <Table className="custom-table">
          {/* Table column header for Name, Type, Last Modified and Size */}
          <TableHead>
            <TableRow>
              <TableCell style={{ borderRight: "2px solid #ccc" }}></TableCell>
              <TableCell style={{ borderRight: "2px solid #ccc" }}>
                Name
              </TableCell>
              <TableCell style={{ borderRight: "2px solid #ccc" }}>
                Type
              </TableCell>
              <TableCell style={{ borderRight: "2px solid #ccc" }}>
                Last Modified
              </TableCell>
              <TableCell style={{ borderRight: "2px solid #ccc" }}>
                Size
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {files.map((file) => (
              // Map over the `files` array to dynamically create a table row for each file or folder
              <TableRow
                key={file.path}
                className={
                  selectedFiles.includes(file.path) ? "selected-row" : "" // Apply CSS to highlight the row if the file is selected
                }
              >
                <TableCell>
                  <input
                    type="checkbox"
                    disabled={file.path.includes(".") ? false : true} // Disable checkbox if type is Folder
                    checked={selectedFiles.includes(file.path)} // Check if the file is selected
                    onChange={() => handleFileSelect(file.path)} // Toggle selection
                  />
                </TableCell>
                <TableCell>{file.path}</TableCell>
                <TableCell>
                  {file.path.includes(".")
                    ? file.path.split(".").pop().toLowerCase() // Extract file extension
                    : "Folder"}{" "}
                </TableCell>
                <TableCell>
                  {file.lastModified
                    ? new Date(file.lastModified).toLocaleString()
                    : "Unknown"}
                </TableCell>
                {/* Calculate file size in MB else use KB if less than 1MB */}
                <TableCell>
                  {file.size
                    ? file.size < 1024 * 1024
                      ? `${(file.size / 1024).toFixed(1)} KB`
                      : `${(file.size / (1024 * 1024)).toFixed(1)} MB`
                    : "-"}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </div>
  );
};

export default ViewFiles;
